
































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { MarketViewModel } from '../viewmodels/market-viewmodel'
import { formatDuration } from '@/modules/ido/business/swap-contract.business'
import moment from 'moment'

@Observer
@Component({
  components: {
    HoverImageSmall: () => import('../components/hover-image-small.vue'),
  },
})
export default class OpenMarket extends Vue {
  @Inject() vm!: MarketViewModel

  createdTime(time) {
    return formatDuration(moment.duration(moment().diff(time))) + ' ago'
  }

  chainEnum(chainId) {
    switch (chainId) {
      case 101:
      case 103:
        return 'sol'
      case 1:
      case 3:
        return 'eth'
      case 56:
      case 97:
        return 'bsc'
    }
  }

  nftSoldHeaders = [
    { text: '#ID', value: 'id', sortable: false },
    { text: '', value: 'image', sortable: false },
    { text: 'Time', value: 'time', sortable: false },
    { text: 'Buyer', value: 'buyer', sortable: false },
    { text: 'Sale Price', value: 'salePrice', sortable: true },
  ]
}
